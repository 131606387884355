@import url("https://fonts.googleapis.com/css?family=Poppins:400,300,700");
// Override default variables before the import
// $body-bg: #000;

$font-family-sans-serif: 'Poppins', sans-serif !important;


.form-check-input {
    width: 60px !important;
    height: 25px !important;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #6C6C6C !important;
}

.menu-flags-button {
    border: none !important;
}

.btn:disabled {
    opacity: 0.25 !important;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';